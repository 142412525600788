import clown from './clown.svg';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={clown} className="App-logo" alt="logo"/>
                <p>
                    This is a fantastic optical illusion -
                    <br/>align your face with the clown and stare into his eyes.
                    <br/><br/><br/>
                    After some time, you will realize there are two clowns looking at each other.
                </p>
            </header>
        </div>
    );
}

export default App;
